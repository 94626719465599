import breakdown from './breakdown'
import device from './device'
import organisation from './organisation'
import personnel from './personnel'
import production from './production'
import trend from './trend'
import workorder from './work-order'
import workcenter from './workcenter-shift'

export default [
  
  { header: 'Configuration' },
  {
    title: 'Admin Users',
    route: 'admin-users-page',
    icon: 'FileIcon',
  },
  {
    title: 'Settings',
    route: 'settings-page',
    icon: 'ShieldIcon',
  },
]
